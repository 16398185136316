.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-form {
  width: 400px;
}

.table-border {
  border-radius: 5px;
  border: #cbcbcb 1px solid;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.text-tiny {
  font-size: 10px;
}

.text-small {
  font-size: 12px;
}

p {
  margin-block: 0;
  font-size: 14px;
}

.text-big {
  font-size: 16px;
}

.text-huge {
  font-size: 18px;
}

ul {
  margin-block: 0;
  font-size: 14px;
}

ol {
  margin-block: 0;
  font-size: 14px;
}
