.label-field {
  font-size: 10px;
  padding-left: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ck-editor__main {
  max-height: 140px;
  overflow: auto;
}

:root {
  --ck-border-radius: 3px;
}

.ck-editor__editable {
  min-height: 140px;
}
